import { Modal } from "bootstrap";

document.documentElement.addEventListener("turbo:load", function () {
  let map;
  const aryAttr = document.getElementById("mapview");
  if (typeof aryAttr != "undefined" && aryAttr != null) {
    const devicesMarkers = JSON.parse(markers());
    const firstDevice = devicesMarkers[0];

    map = L.map("map").setView(
      [firstDevice["device_lat"], firstDevice["device_lon"]],
      13
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 30,
      attribution: "© OpenStreetMap",
    }).addTo(map);
    devicesMarkers.forEach((element) => {
      const marker = L.marker([
        element["device_lat"],
        element["device_lon"],
      ]).addTo(map);
      marker.on("mouseover", () => {
        marker.bindPopup("<b>" + element["device_name"] + "</b>").openPopup();
      });
      marker.on("click", onMarkerClick.bind(this, element["device_id"]));
    });
  }

  reloadMap(map);
});

function markers() {
  const aryAttr = document.getElementById("mapview");
  return aryAttr.dataset.markers;
}

function reloadMap(map) {
  const tabMap = document.getElementById("mapview-tab");
  if (typeof tabMap != "undefined" && tabMap != null) {
    tabMap.addEventListener("shown.bs.tab", (event) => {
      map.invalidateSize();
    });
  }
}

function onMarkerClick(device_id) {
  const modal = Modal.getOrCreateInstance(document.getElementById(`modal-${device_id}`));
  modal.show();
}
