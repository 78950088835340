import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "astroclock",
    "alsmode",
    "centralalsmode",
    "astroOffsetSunset",
    "astroOffsetSunrise",
  ];

  connect() {
    if (this.astroclockTarget.checked == false) {
      this.disableAstroclockInputs(true);
    }
  }

  disableCheckbox() {
    event.preventDefault();
    const btn = event.target;
    const checkBox = btn.dataset.checkboxname;
    const divElem = document.getElementById(checkBox);
    this.toggleCheckboxState(btn, divElem);
  }

  toggleCheckboxState(btn, divElem) {
    let inputs = divElem.querySelectorAll("input, checkbox");

    if (btn.innerHTML == "Disable field") {
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].disabled = true;
      }
      btn.innerHTML = "Enable field";
      btn.classList.remove("btn-danger");
      btn.classList.add("btn-success");
    } else if (btn.innerHTML == "Enable field") {
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].disabled = false;
      }
      btn.innerHTML = "Disable field";
      btn.classList.remove("btn-success");
      btn.classList.add("btn-danger");
    } else {
      null;
    }
  }

  disableAls() {
    if (this.astroclockTarget.checked == true) {
      this.disableAstroclockInputs(false);
      this.alsmodeTarget.checked = false;
    } else {
      if (this.centralalsmodeTarget.checked == true) {
        alert("Astroclock is required for fallback purposes");
        this.astroclockTarget.checked = true;
      } else this.disableAstroclockInputs(true);
    }
  }

  disableAlsEnableAstro() {
    if (this.centralalsmodeTarget.checked == true) {
      this.alsmodeTarget.checked = false;
      this.astroclockTarget.checked = true;
      this.disableAstroclockInputs(false);
    }
  }

  disableAstroclock() {
    if (this.alsmodeTarget.checked == true) {
      this.centralalsmodeTarget.checked = false;
      this.astroclockTarget.checked = false;
      this.disableAstroclockInputs(true);
    }
  }

  disableAstroclockInputs(state) {
    this.astroOffsetSunsetTarget.disabled = state;
    this.astroOffsetSunriseTarget.disabled = state;
  }
}
