import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paste"];

  paste() {
    event.preventDefault();
    const pasteButton = document.getElementById("serialNumberPaste");
    pasteButton.addEventListener("click", function () {
      navigator.clipboard.readText().then(
        (cliptext) =>
          (document.getElementById("serial_number").value = cliptext),
        (err) => console.log(err)
      );
    });
  }
}
